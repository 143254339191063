@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
  background-color: #eff3f8;
  
  margin: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-header {
  height: 41px;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0px 8px 24px 7px rgba(99,102,241,0.1);
  z-index: 1;
}
.ant-layout-header ul.ant-menu {
  line-height: 3;
}

.logo {
  float: left;
  width: 45px;
  height: 45px;
  margin: 0;
  background-size: cover;
  background-image: url('./logo.png');
}

.data-table-filter-list ul {
  list-style: none;
  padding: 0;
}
.data-table-filter-list ul li {
  border-block-end: none !important;
  padding: 8px 8px 8px 15px;
  cursor: pointer;
}
.data-table-filter-list ul li:hover {
  background-color: #fafafa;
}

.custom-data-table .ant-divider {
  margin: -10px 0 !important;
  border-block-start: 1px solid #e3e3e3 !important;
}

th.ant-table-cell {
  padding: 8px !important;
}

.ant-table-cell {
  padding: 0 !important;
}

.ant-table-preserve-paddings th.ant-table-cell {
  padding: 16px !important;
}

.ant-table-preserve-paddings .ant-table-cell {
  padding: 16px !important;
}

.ant-table-cell-override {
  padding: 8px;
  cursor: pointer;
}

.ant-table-cell-override-non-clickable {
  padding: 8px;
  cursor: default;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-body {
  padding: 3px 24px !important;
}

.ant-modal .ant-modal-header {
  padding: 10px 24px !important;
  border-bottom: 1px solid #f0f0f0;
}

.ant-modal-header .ant-modal-close {
  top: 13px !important;
}

.ant-modal .ant-modal-footer {
  padding: 10px 24px !important;
  border-top: 1px solid #f0f0f0;
}

.custom-popover-label {
  color: rgba(0,0,0,.55);
  font-size: 11;
}

.custom-popover-item {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background .3s ease;
}

.custom-popover-item:hover{
  background-color: rgba(0,0,0,.04);
}

.custom-popover-item.out-of-range {
  color: #f5222d;
  text-decoration: line-through;
  cursor: not-allowed;
  opacity: 0.5;
}

.readonly-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent;
}

.override-row-padding-8 .ant-table-cell {
  padding: 8px !important;
}

.custom-timeline-container .ant-timeline .ant-timeline-item:not(:last-child) {
  padding-bottom: 60px !important;
}

.pdf-page {
  transform-origin: 0 0;
  transform: scale(var(--scale, 1));
}

.pdf-container {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  background-color: #f3f3f3;
}

button.pdfButton {
  background-color: #fff !important;
  border: none;
  font-size: large;
  line-height: 1;
}

.pdfZoomContainer button {
  height: 40px;
}
.pdfZoomContainer:hover,
.pdfActionContainer:hover {
  opacity: 1;
  transition: ease-out 0.2s;
}
.pdfZoomContainer {
  opacity: 0.3;
  transition: ease-in 0.1s;
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  top: 15%;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.pdfActionContainer {
  opacity: 0.3;
  position: absolute;
  transition: ease-in 0.1s;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .logo {
    display: none;
  }

  .ant-layout-header {
    padding: 0;
  }
}

.map-container {
  height: 100%;
  width: 100%;
}

/* Ejemplo de estilos para las filas con estado "activo" */
.table-row-active {
  background-color: #e6f7ff; /* Color de fondo para filas "activas" */
  /* Otros estilos personalizados para filas "activas" */
}

/* Ejemplo de estilos para las filas con estado "inactivo" */
.table-row-inactive {
  background-color: #ffe6e6; /* Color de fondo para filas "inactivas" */
  /* Otros estilos personalizados para filas "inactivas" */
}

.ant-table-cell:has(> .status-green) {
  background-color: #52c41a;
}

.ant-table-row:hover .ant-table-cell:has(> .status-green) {
  background-color: #52c41a !important;
}

.ant-table-cell:has(> .status-yellow) {
  background-color: #fadb14;
}

.ant-table-row:hover .ant-table-cell:has(> .status-yellow) {
  background-color: #fadb14 !important;
}

.ant-table-cell:has(> .status-red) {
  background-color: #f5222d;
}

.ant-table-row:hover .ant-table-cell:has(> .status-red) {
  background-color: #f5222d !important;
}

.ant-table-cell:has(> .status-blue) {
  background-color: #1677ff;
}

.ant-table-row:hover .ant-table-cell:has(> .status-blue) {
  background-color: #1677ff !important;
}


.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.custom-card-header .ant-card-head {
  text-align: center;
  min-height: 35px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 16px;
}